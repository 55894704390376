import { Main } from "../components/Main/index";

import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";

export default function Home() {
  return (
    <div>
      <div className="bg-gradient-to-b">
        <div className=" bg-main-pattern bg-no-repeat bg-cover bg-center h-[789px]">
          <Header />
          <Main />
          <Footer />
        </div>
      </div>
    </div>
  );
}
