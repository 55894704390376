import React from "react";
import { Wrapper } from "../Wrapper";

export const Footer: React.FC = () => {
  return (
    <Wrapper>
      <footer className="flex flex-col mt-24">
        <div className="flex flex-row gap-24 img:px-36 mt-20 items-center m-auto">
          <div className="img:w-60 w-52 tablet:block hidden">
            <img src="/images/footerimg.png" alt="teste" />
          </div>
          <div className="text-base img:block hidden">
            <p>
              Com a união de diferentes áreas de conhecimento acerca de branding
              e softwere engine, a Midas Studio nasceu com o propósito de
              oferecer um serviço completo para todos que desejam ser inseridos
              e reconhecidos no mercado digital.
            </p>
          </div>
          <div className="mb-3 flex flex-col items-center">
            <h1 className="w-[138px] mb-5 font-title font-semibold text-xl text-center">
              Redes Sociais
            </h1>
            <div className="flex flex-row gap-[26px] items-center">
              <a
                href="https://www.instagram.com/midas_studio/"
                rel="noreferrer"
                target={"_blank"}
              >
                <img
                  src="/images/insta.png"
                  alt="insta"
                  width={20}
                  height={20}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/midas-company01/about/"
                rel="noreferrer"
                target={"_blank"}
              >
                <img
                  src="/images/linkedin.png"
                  alt="linkedin"
                  width={20}
                  height={20}
                />
              </a>
              <a
                href="https://www.behance.net/midas_studio"
                rel="noreferrer"
                target={"_blank"}
              >
                <img
                  src="/images/behance.png"
                  alt="behance"
                  width={25}
                  height={25}
                />
              </a>
            </div>
          </div>
        </div>
        <div className="border-t-2 border-[#F3F3F3] mt-16 py-8 flex justify-center m-auto w-4/5">
          <p className=" font-poppins">© Todos os direitos reservados - 2022</p>
        </div>
      </footer>
    </Wrapper>
  );
};
