import React, { CSSProperties, useEffect, useRef, useState } from "react";
import { Swiper as SwiperRC, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

type SwiperHOCProps = {
  slideComponent: React.FC<any>;
  data: Array<any>;
};

const SWIPER_STYLES: CSSProperties = {
  width: "90%",
  height: "100px",
};

export const Swiper: React.FC<SwiperHOCProps> = ({
  slideComponent: SlideComponent,
  data,
}) => {
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    // Verifica se todas as imagens já foram carregadas e aplica os estilos
    const allImages = document.querySelectorAll(".swiper-slide img");
    const promises = Array.from(allImages).map((img) => {
      if (img instanceof HTMLImageElement) {
        if (img.complete) return Promise.resolve();
        return new Promise((resolve) => {
          img.addEventListener("load", resolve);
        });
      }
      return Promise.resolve();
    });

    Promise.all(promises).then(() => {
      setImagesLoaded(true);
    });
  }, []);
  const handleImageLoad = () => {
    setImagesLoaded(true);
  };

  return (
    <>
      <SwiperRC
        modules={[Autoplay]}
        loop={true}
        spaceBetween={80}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        breakpoints={{
          1100: {
            slidesPerView: 4,
          },
          940: {
            slidesPerView: 3,
          },
          360: {
            slidesPerView: 2,
          },
        }}
        slidesPerView={2}
        style={SWIPER_STYLES}
      >
        {data.map((item, index) => (
          <SwiperSlide
            key={index}
            style={imagesLoaded ? { width: "auto", height: "auto" } : {}}
            onLoad={handleImageLoad}
          >
            <SlideComponent {...item} />
          </SwiperSlide>
        ))}
      </SwiperRC>
    </>
  );
};
