import { useRef, useState } from "react";
import { ProjectCard } from "./ProjectCard";
import { Wrapper } from "../../../../Wrapper";
import projects from "../../../../../data/projects.json";
import { FcNext, FcPrevious } from "react-icons/fc";
export const ProjectsSection = () => {
  const proj = projects.projects;
  const cat = ["Todos", ...projects.categories]; // Adicione "Todos" como a primeira categoria
  const projectsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState("Todos"); // Inicialize com "Todos"
  const projectsRef = useRef<HTMLDivElement>(null);

  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;

  const filteredProjects =
    selectedCategory === "Todos"
      ? proj
      : proj.filter((project: any) =>
          project.categories.includes(selectedCategory)
        );

  const currentProjects = filteredProjects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  const paginate = (pageNumber: any) => {
    if (
      pageNumber < 1 ||
      pageNumber > Math.ceil(filteredProjects.length / projectsPerPage)
    )
      return;
    setCurrentPage(pageNumber);
    if (projectsRef.current) {
      window.scrollTo({
        top: projectsRef.current.offsetTop,
        behavior: "auto",
      });
    }
  };

  const handleCategoryFilter = (category: any) => {
    setSelectedCategory(category);
    setCurrentPage(1);
  };

  return (
    <section
      ref={projectsRef}
      className="bg-[#0F0F0F] mt-[200px] mb-[200px] pt-10 pb-24"
    >
      <Wrapper>
        <div className="text-center flex flex-col gap-16 justify-center items-center mt-8">
          <h1 className="m-auto font-title text-title font-semibold mb-8">
            Nossos Projetos
          </h1>

          <div className="flex flex-row self-start ml-32 gap-4">
            {cat.map((category: any) => (
              <button
                key={category}
                className={`text-black text-sm font-bold px-4 py-2 rounded-[5px] lg:block  hidden ${
                  selectedCategory === category
                    ? "bg-[#1C1C22] text-white"
                    : "bg-white text-black hover:bg-gray-300 transition-all ease-in"
                }`}
                onClick={() => handleCategoryFilter(category)}
              >
                {category}
              </button>
            ))}
          </div>

          <div className="flex flex-col gap-12">
            {currentProjects.map((project: any) => (
              <ProjectCard
                key={project.title}
                title={project.title}
                description={project.description}
                image={project.image}
                categories={project.categories}
                link={project.link}
              />
            ))}
          </div>

          <div className="flex flex-row gap-2">
            <button
              className="bg-[#1C1C22] text-white text-sm font-bold pl-4 pr-2 py-2 rounded-[5px]"
              onClick={() => {
                paginate(currentPage - 1);
              }}
            >
              <img className="" src="/images/backicon.svg" alt="" />
            </button>
            {Array.from({
              length: Math.ceil(filteredProjects.length / projectsPerPage),
            }).map((_, index) => (
              <button
                key={index}
                className={`bg-[#1C1C22] text-white text-sm font-bold px-4 py-2 rounded-[5px] ${
                  currentPage === index + 1 ? "bg-secundary" : "bg-[#1C1C22]"
                }`}
                onClick={() => paginate(index + 1)}
              >
                {index + 1}
              </button>
            ))}
            <button
              className="bg-[#1C1C22] text-white text-sm font-bold px-3 py-2 rounded-[5px]"
              onClick={() => {
                paginate(currentPage + 1);
              }}
            >
              <img src="/images/nexticon.svg" alt="" />
            </button>
          </div>
        </div>
      </Wrapper>
    </section>
  );
};
