import React, { useEffect, useRef, useState } from "react";
import { Wrapper } from "../../../../Wrapper";
import { CompanyCard } from "./CompanyCard";
import { Swiper } from "../../../../Swiper";

const MOCKUP_DATA = [
  { img: "/images/companies/residenceweb.png" },
  { img: "/images/companies/urmob.png" },
  { img: "/images/companies/virtumed.png" },
  { img: "/images/companies/criptoverse.png" },
  { img: "/images/companies/brodr.png" },
  { img: "/images/companies/meuroteiro.png" },
  { img: "/images/companies/posturegenius.png" },
  { img: "/images/companies/reisbbq.png" },
  { img: "/images/companies/bafpay.png" },
  { img: "/images/companies/kltech.png" },
];

export const AboutSection: React.FC = () => {
  const contentRef = useRef<HTMLDivElement>(null);

  const [clients, setClients] = useState<number>(0);
  const [projects, setProjects] = useState<number>(0);
  const [activeUsers, setActiveUsers] = useState<number>(0);
  const [yearsOfExperience, setYearsOfExperience] = useState<number>(0);

  useEffect(() => {
    const animatedNumbers = [
      { state: clients, setState: setClients, target: 20, smooth: 100 },
      { state: projects, setState: setProjects, target: 45, smooth: 100 },
      {
        state: activeUsers,
        setState: setActiveUsers,
        target: 1000000,
        smooth: 50,
      },
      {
        state: yearsOfExperience,
        setState: setYearsOfExperience,
        target: 3,
        smooth: 100,
      },
    ];

    const handleScroll = () => {
      const content = contentRef.current;
      if (content) {
        if (window.scrollY + window.innerHeight >= content.offsetTop + 300) {
          content.classList.remove("opacity-0");

          animatedNumbers.forEach(({ state, setState, target, smooth }) => {
            const step = Math.ceil(target / 1000); // You can adjust the step for a smoother animation

            const interval = setInterval(() => {
              setState((prev) => {
                const newValue = prev + step > target ? target : prev + step;
                return Math.round(newValue * 10) / 10; // Round to one decimal place
              });

              if (state >= target) {
                clearInterval(interval);
              }
            }, smooth);
          });
        } else {
          content.classList.add("opacity-0");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      animatedNumbers.forEach(({ state }) => {
        clearInterval(state);
      });
    };
  }, [clients, projects, activeUsers, yearsOfExperience]);

  const formattedActiveUsers = new Intl.NumberFormat("pt-BR", {
    style: "decimal",
  }).format(activeUsers);

  return (
    <Wrapper>
      <section
        className="w-full mt-44 opacity-0 transition-opacity duration-1000 ease-in-out"
        id="about"
        ref={contentRef}
      >
        <div className="m-auto">
          <div className="flex flex-col justify-center items-center gap-9 px-[60px] md:px-[155px] mt-16 ">
            <h1 className="font-title text-subtitle md:text-title font-semibold mb-8">
              Midas Studio
            </h1>

            <div className="flex flex-row items-start justify-center gap-16 flex-wrap">
              <div className="flex flex-col items-center justify-center gap-5">
                <h1 className="text-secundary font-bold font-body text-xl md:text-2xl">
                  {clients}
                </h1>
                <p className="text-lg md:text-xl font-title text-center">
                  Clientes
                </p>
              </div>
              <div className="flex flex-col items-center justify-center gap-5">
                <h1 className="text-secundary font-bold font-body text-xl md:text-2xl">
                  {projects}
                </h1>
                <p className="text-lg md:text-xl font-title text-center">
                  Projetos
                </p>
              </div>
              <div className="flex flex-col items-center justify-center gap-5">
                <h1 className="text-secundary font-bold font-body text-xl md:text-2xl">
                  {`${formattedActiveUsers}+`}
                </h1>
                <p className="text-lg md:text-xl font-title text-center max-w-[185px]">
                  Usuarios ativos em nossas soluções
                </p>
              </div>
              <div className="flex flex-col items-center justify-center gap-5">
                <h1 className="text-secundary font-bold font-body text-xl md:text-2xl">
                  {yearsOfExperience}
                </h1>
                <p className="text-lg md:text-xl font-title text-center">
                  Anos de mercado
                </p>
              </div>
            </div>

            <p className="text-center mt-12">
              O estúdio nasceu da ideia de unir diferentes áreas do visual com a
              estrutura sólida que nossos programadores podem proporcionar,
              unindo campos do web design (UI/UX), design gráfico, ilustrações e
              motion design somada com a experiência e qualificação que apenas
              nossos devs têm.
            </p>

            <div className="mt-16 mb-8 self-center flex flex-col justify-center overflow-hidden">
              <h1 className="font-title text-[26px] font-semibold text-center mb-20 ">
                Empresas que confiam em nós
              </h1>
            </div>
          </div>

          <div>
            <Swiper slideComponent={CompanyCard} data={MOCKUP_DATA} />
          </div>
        </div>
      </section>
    </Wrapper>
  );
};
