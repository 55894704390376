import { useEffect, useState } from "react";
import { Wrapper } from "../../../Wrapper";

export const Presentation: React.FC = () => {
  const [text, setText] = useState("");
  const fullText = "Transformando seu negócio em ouro";
  const typingSpeed = 75;
  const wordToStyle = "ouro";
  const [desc, setDesc] = useState(false);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const typeText = (currentIndex: number) => {
      if (window.scrollY > 100) {
        setText(fullText);
        setDesc(true);
        return;
      }
      if (currentIndex < fullText.length) {
        setText((prev) => prev + fullText[currentIndex]);
        timeoutId = setTimeout(() => typeText(currentIndex + 1), typingSpeed);
      } else {
        setDesc(true);
      }
    };

    timeoutId = setTimeout(() => typeText(0), typingSpeed);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Wrapper>
      <section className="flex flex-col mx-auto tablet:mt-[328px] mt-[158px]">
        <div className="flex flex-col justify-center mb-[100px]">
          <h1 className="font-title font-bold text-center mb-8 mx-3 tablet:mx-0 tablet:text-title desktop:text-h1 text-3xl">
            {text.split(" ").map((word, index) => (
              <span
                key={index}
                className={word === wordToStyle ? "text-secundary" : ""}
              >
                {word + " "}
              </span>
            ))}
            <span className="caret-animation">|</span>
          </h1>
          <p
            className={`text-base desktop:text-paragraph text-center font-normal w-3/4 self-center lg:max-w-2xl sm:max-w-xl transition-opacity duration-1000 ${
              desc ? "opacity-100" : "opacity-0"
            }`}
          >
            Impulsionando seu sucesso e liderando a inovação com design criativo
            de excelência
          </p>
        </div>
        <div className="w-[76%] m-auto img:h-[634px] tablet:h-[500px] phone:h-[200px]">
          <iframe
            className={`mx-auto w-full h-full transition-opacity duration-1000 ${
              desc ? "opacity-100" : "opacity-0"
            }`}
            src="https://www.youtube.com/embed/rrnQJ1lXOw8"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </section>

      <style>
        {`
          .caret-animation {
            animation: caret-blink 0.8s infinite;
          }

          @keyframes caret-blink {
            0% {
              opacity: 1;
            }
            50% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        `}
      </style>
    </Wrapper>
  );
};
