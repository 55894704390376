import { Services } from "./components/sections/Services/Services";
import { Presentation } from "./components/sections/Presentation";

import { AboutSection } from "./components/sections/About/AboutSection";
import { ProjectsSection } from "./components/sections/Projects/Projects";
import { ContactSection } from "./components/sections/Contact/ContactSetion";

export const Main: React.FC = () => {
  return (
    <main>
      <Presentation />
      <div id="services"></div>
      <Services />
      <div id="projects"></div>
      <ProjectsSection />
      <ContactSection />

      <div id="about">
        <br />
      </div>
      <AboutSection />
    </main>
  );
};
