type ServiceCardProps = {
  title: string;
  description: string;
  icon: string;
};

export const ServiceCard: React.FC<ServiceCardProps> = ({
  title,
  description,
  icon,
}: ServiceCardProps) => {
  return (
    <div className="flex flex-col justify-center items-center max-w-[303px]">
      <img src={`/images/serviceicons/${icon}.png`} alt={title} />

      <h1 className="text-xl font-bold font-title mt-7">{title}</h1>
      <p className="text-center mt-4 text-base">{description}</p>
    </div>
  );
};
