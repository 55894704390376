import { useEffect, useRef } from "react";
import { Wrapper } from "../../../../Wrapper";
import { ServiceCard } from "./ServiceCard";

export const Services: React.FC = () => {
  const contentRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleScroll = () => {
      const content = contentRef.current;
      if (content) {
        if (window.scrollY + window.innerHeight >= content.offsetTop + 300) {
          content.classList.add("opacity-100");
        } else {
          content.classList.remove("opacity-100");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Wrapper>
      <section className="flex flex-col justify-center items-center mt-72">
        <div
          ref={contentRef}
          className="flex flex-col justify-center items-center opacity-0 transition-opacity duration-700 ease-in-out"
        >
          <h1 className="font-title text-title font-semibold mb-[74px]">
            Nossos Serviços
          </h1>
          <div className="flex flex-row flex-wrap gap-y-20 gap-x-28 justify-center items-center ">
            <ServiceCard
              title="Desenvolvimento de Sites"
              description="Crie um site exclusivo e impactante. Crie sites impactantes e funcional com nossa equipe."
              icon="web"
            />
            <ServiceCard
              title="Desenvolvimento Mobile"
              description="Entre no mundo Mobile com nossos aplicativos de alto desempenho, feitos sob medida para suas necessidades."
              icon="mobile"
            />
            <ServiceCard
              title="Identidade Visual"
              description="Sua marca é nossa prioridade. Desenvolvemos logotipos e elementos visuais que refletem sua marca."
              icon="idvisual"
            />
            <ServiceCard
              title="UX/UX Design"
              description="Experiência do usuário e design de interface são a base do nosso sucesso. Produtos atraentes e fáceis de usar."
              icon="uiux"
            />
            <ServiceCard
              title="Design de Produto"
              description="Transforme ideias em produtos incríveis. Do conceito à entrega, cativamos o público com nosso design de produtos."
              icon="produto"
            />
            <ServiceCard
              title="Marketing"
              description="Promova seus produtos e serviços. Nossa equipe de marketing cria estratégias para alcançar seu público-alvo."
              icon="marketing"
            />
          </div>
        </div>
      </section>
    </Wrapper>
  );
};
