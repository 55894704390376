import { Wrapper } from "../../../../Wrapper";

export const ContactSection: React.FC = () => {
  return (
    <Wrapper>
      <section className="flex flex-col mx-auto mt-10 md:mt-20 justify-center items-center px-10">
        <div className="flex flex-col md:flex-row md:gap-8 w-full max-w-screen-xl">
          <div className="bg-[#1C1D1F] w-full max-h-[539px] md:w-1/2 pt-8 md:pt-16 rounded-[16px] px-4 md:px-12 pb-8 md:pb-16 md:block flex flex-col items-center justify-center">
            <h1 className="text-3xl md:text-3xl lg:text-4xl xl:text-5xl font-bold font-title text-center md:text-start mt-4 md:mt-0">
              Precisa de um projeto?
            </h1>
            <p className="text-base md:text-start text-center md:text-xl lg:text-2xl mt-4 md:mt-8 ml-4 md:mr-11 lg:mr-[82px] md:ml-0">
              Agende uma conversa com nossa equipe. Estamos dispostos a entender
              sobre sua marca para oferecer uma solução eficiente e criativa.
            </p>
            <a
              href="https://wa.me/5531995555097?text=Ol%C3%A1%2C+gostaria+de+saber+mais+sobre+a+Midas+Studio."
              target="_blank"
              rel="noreferrer"
            >
              <button className="bg-white mt-4 md:mt-14 text-black text-base md:text-base font-bold px-8 py-3 rounded-[5px] font-title hover:bg-gray-300 transition-all ease-in">
                Agendar Call
              </button>
            </a>
          </div>
          <div className="flex flex-col mt-8 md:mt-0 w-full md:w-1/2">
            <div className="bg-[#1C1D1F] w-full h-[50vw] md:h-[255px] rounded-[16px]">
              <img
                src="/images/needproject.jpg"
                alt=""
                className="w-full h-full rounded-[16px]"
              />
            </div>
            <div className="bg-[#1C1D1F] w-full mt-6 md:mt-8 flex items-center h-[300px] md:h-[255px] rounded-[16px]">
              <p className="text-base md:text-xl text-center md:text-start px-4 md:px-[45px]">
                "As marcas que experimentam o maior crescimento são aquelas que
                reconhecem o valor do design, principalmente ao investir na
                aprimoração de sua identidade visual."
              </p>
            </div>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};
