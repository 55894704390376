import { CategoryWidget } from "./Widgets/CategoryWidget";

type ProjectCardProps = {
  title: string;
  description: string;
  image: string;
  categories?: string[];
  link: string;
};

export const ProjectCard = ({
  title,
  description,
  image,
  categories,
  link,
}: ProjectCardProps) => {
  return (
    <div className="w-[296px] sm:w-[496px] xl:w-[1139px] bg-[#1C1C22] rounded-[8px] hover:bg-[#121216] transition-colors duration-300">
      <div className="flex flex-col xl:flex-row xl:m-[25px] m-6 xl:gap-10 gap-8 justify-start">
        <div className="xl:min-w-[496px] overflow-hidden">
          <a href={link} target="_blank" rel="noreferrer">
            {" "}
            <img
              src={`/images/projects/${image}`}
              className="rounded-[4px] w-full"
              alt=""
            />
          </a>
        </div>

        <div className="flex flex-col justify-start items-start gap-8 sm:mt-4">
          <div className="flex flex-wrap gap-[14px]">
            {categories?.map((category, index) => (
              <CategoryWidget key={index} category={category} />
            ))}
          </div>
          <div className=" mr-8">
            <a href={link} target="_blank" rel="noreferrer">
              {" "}
              <h1 className="text-[24px] md:text-[30px] font-bold font-title text-start">
                {title}
              </h1>
            </a>
            <p className="text-start mt-4">{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
