import { Wrapper } from "../Wrapper";
import { useEffect, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";

type LinkType = { name: string; to: string; className?: string };

export const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const [active, setActive] = useState("");
  useEffect(() => {
    const path = window.location.hash;
    console.log(path);
    if (path === "#/" || path === "#" || path === "" || path === "/") {
      setActive("home");
    } else if (path === "#/projects") {
      setActive("projects");
    }
  }, []);

  const navLinks: LinkType[] = [
    {
      name: "Home",
      to: "/",
      className: `${active === "home" ? "text-secundary" : null}`,
    },
    { name: "Sobre", to: "/#about" },
    {
      name: "Serviços",
      to: "/#services",
    },
    {
      name: "Projetos",
      to: "/#projects",
      className: `${active === "track" ? "text-secundary" : null}`,
    },
  ];

  return (
    <Wrapper>
      <header className="w-full flex flex-row lh:justify-center justify-around tablet:pt-5 pt-10 items-center  lg:gap-x-28 laptop:gap-x-16 gap-x-6">
        <Link to={"/"}>
          <div className="w-68 tablet:block hidden">
            <img src="/images/logo.png" alt="Midas Company" />
          </div>
        </Link>
        <nav className="pt-12 pb-12 hidden lg:block">
          <ul className="flex flex-row gap-12 justify-center">
            {navLinks.map((link, index) => (
              <li key={index} className="text-xl font-body">
                <Link to={link.to} className={`${link.className}`}>
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
        <div>
          <button className="bg-secundary text-black text-sm font-bold px-4 py-2 rounded-[5px] lg:block hidden">
            <a href="https://api.whatsapp.com/send?phone=5531995555097&text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20a%20Midas%20Studio!">
              Entre em contato
            </a>
          </button>
        </div>
        <div className="lg:hidden block">
          {isMenuOpen ? (
            <button
              onClick={() => {
                setIsMenuOpen(!isMenuOpen);
              }}
            >
              <img src="/images/menu.png" alt="Menu" width={38} height={28} />
            </button>
          ) : null}
          <div
            className={`img:w-[36%] w-[80%] h-screen fixed top-0 right-0 bg-[#161313] z-50 transform transition-all ease-in-out duration-300 ${
              !isMenuOpen ? "translate-x-0" : "translate-x-full"
            }`}
          >
            <div className="w-full items-start justify-end flex py-5 pr-6 bg-secundary">
              <button
                onClick={() => {
                  setIsMenuOpen(!isMenuOpen);
                }}
              >
                <img
                  src="/images/close.png"
                  alt="Menu"
                  width={30}
                  height={30}
                />
              </button>
            </div>
            <div className="flex flex-col items-start justify-start pl-5 pt-20 h-full">
              <ul className="flex flex-col gap-4 justify-center">
                {navLinks.map((link, index) => (
                  <li key={index} className="text-xl font-body">
                    <Link to={link.to}>{link.name}</Link>
                  </li>
                ))}
                <li>
                  <a
                    href="https://api.whatsapp.com/send?phone=5531995555097&text=Ol%C3%A1!%20Gostaria%20de%20saber%20mais%20sobre%20a%20Midas%20Studio!"
                    className="text-xl font-body"
                  >
                    Entre em contato
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </Wrapper>
  );
};
